body {
  word-break: break-word;
}

body:not(.modal-open) {
  overflow-y: scroll !important;
}

.table-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .ri-delete-bin-fill {
    color: red;
  }
}

.layout-main-container {
  padding-bottom: 0 !important;

  .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .custom-search-input {
      @media (max-width: 991px) {
        width: 200px;
      }

      input {
        @media (max-width: 991px) {
          padding-left: 35px;
          padding-right: 12px;
        }
      }
    }

    .contain-header-right {
      button.btn {
        @media (max-width: 991px) {
          padding: 8px 8px;
          font-size: 12px;
        }
      }
    }
  }

  .container-body {
    .table-title {
      font-size: large;
      font-weight: 600;
    }
  }
}

// for custom search field
.custom-search-input {
  position: relative;

  input {
    height: 45px;
    padding-left: 40px;
    padding-right: 30px;
    background: white;
    border-radius: 50px;
    font-size: 16px;
  }

  .search-icon {
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    line-height: 45px;
    left: 13px;
    top: 0;
    color: #878a99;
  }

  .clear-icon {
    right: 7px;
    left: auto !important;
  }
}

// for custom search column wise
.column-search-input {
  position: relative;

  input {
    height: 38px;
    padding-left: 28px;
    padding-right: 25px;
    background: white;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    @media (max-width: 991px) {
      padding-left: 24px;
      padding-right: 8px;
    }

    &::placeholder {
      color: hsl(0, 0%, 50%);
      font-weight: 450;
    }
  }

  .search-icon {
    cursor: pointer;
    position: absolute;
    font-size: 16px;
    line-height: 38px;
    left: 7px;
    top: 0;
    color: hsl(0, 0%, 50%);
    font-weight: 450;
  }

  .clear-icon {
    right: 7px;
    left: auto !important;
  }
}

// for custom column wise date range selection
.column-date-range {
  position: relative;

  input {
    height: 38px;
    padding-left: 28px;
    padding-right: 25px;
    background: white;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;

    &::placeholder {
      color: hsl(0, 0%, 50%);
      font-weight: 450;
    }
  }

  .search-icon {
    cursor: pointer;
    position: absolute;
    font-size: 16px;
    line-height: 38px;
    left: 7px;
    top: 0;
    color: hsl(0, 0%, 50%);
    font-weight: 450;
  }

  .clear-icon {
    right: 7px;
    left: auto !important;
  }
}

// table no-records message
.table-no-records-message {
  width: 100%;
  margin-bottom: 0px !important;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// to avoid body padding when modal is opened
.modal-open {
  padding-right: 0px !important;
}

// custom loader
.custom-loader-backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(4px);

  .backdrop-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.custom-validation-style {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #f06548;
}

.custom-select > div {
  background-color: #fff !important;
  border: 1px solid var(--vz-input-border) !important;
}

.select-error > div {
  border: 1px solid #f06548 !important;
}

.custom-select {
  div:last-child {
    max-height: 180px;
  }

  div {
    &[id$="-listbox"] {
      z-index: 5 !important;
    }
  }
}

.custom-multi-select {
  div:last-child {
    border: none !important;
  }
}

.disabled-select > div {
  background-color: var(--vz-input-disabled-bg) !important;
}

.data-table {
  overflow-y: auto !important;
  // height: calc(100vh - 295px);
  max-height: calc(100vh - 295px) !important;
  @media (max-width: 1199px) {
    max-height: calc(100vh - 365px) !important;
  }
}

.mini-data-table {
  overflow-y: auto !important;
  max-height: 315px !important;
}

.expandalbe-data-table {
  overflow-y: auto !important;
  max-height: 615px !important;

  .rdt_TableHead {
    z-index: 2;
  }
}

.child-data-table-card {
  background-color: var(--vz-body-bg);

  .child-data-table {
    .rdt_Table [role="columnheader"] {
      height: auto;
    }

    .rdt_TableHead {
      z-index: 1;
    }

    .rdt_TableHeadRow {
      background-color: #dfdbf1;
    }
  }

  .rdt_Pagination {
    background-color: #dfdbf1 !important;
  }
}

// custom invalid message
.custom-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #f06548 !important;
}

.rdt_Table [role="columnheader"] {
  overflow: visible;
  display: block;
}

// custom range slider
.custom-range-slider {
  height: 5px !important;
  margin: 0px !important;

  .rangeslider__fill {
    background-color: #4b38b3 !important;
  }

  .rangeslider__handle {
    width: 18px !important;
    height: 18px !important;
    border-radius: 30px !important;

    &::after {
      width: 10px !important;
      height: 10px !important;
      top: 3px !important;
      left: 3px !important;
    }
  }
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23a59cd9%27/%3e%3c/svg%3e");
}

// custom doc preview
.uploaded-doc-preview {
  border: 1px solid var(--vz-input-border);
  padding: 0.5rem 0.9rem;
  border-radius: 0.25rem;
  @media (max-width: 991px) {
    padding: 8px 5px;
    font-size: 10px !important;
  }
}

// custom color picker
.custom-color-picker {
  .swatch-container {
    height: 38px;
    width: 38px;
    padding: 5px;
    background: #fff;
    border: 1px solid var(--vz-input-border);
    border-radius: 0.25rem;
    // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    // display: inline-block;
    cursor: pointer;

    .swatch-cell {
      width: 100%;
      height: 100%;
      border-radius: 0.25rem;
    }
  }

  .color-picker-popover {
    position: absolute;
    z-index: 2;

    .color-picker-cover {
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }
}

.custom-tag-chip {
  padding: 8px 16px;
  border-radius: 0.25rem;
  text-align: center;
  // margin: 4px;
}

// sortable column header
.sortable-column-header {
  cursor: pointer;

  &:hover {
    opacity: 0.7;

    .sorting-icon {
      transition: 0.3s all ease-in-out;
    }
  }
}

// custom breadcrumb
.custom-breadcrumb {
  // padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    color: var(--vz-heading-color);
    font-weight: 700;
    font-size: large !important;
    @media (max-width: 991px) {
      font-size: 17px !important;
    }
  }
}

// card-section
.card-section {
  .card-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .card-title {
      font-size: large;
      font-weight: 600;
    }
  }
}

// table inside card for the dynamic form
.card-subtable-section {
  border: 1px solid var(--vz-input-border);
  border-radius: 4px;

  .subtable-header {
    background-color: var(--vz-table-bg);
  }

  hr {
    margin: 0;
  }

  .subtable-container {
    .subtable-row {
      .form-control:disabled {
        @media (max-width: 991px) {
          padding: 8px 8px;
        }

        .form-control {
          @media (max-width: 991px) {
            padding: 8px 6px;
          }
        }
      }
    }
  }

  .subtable-row {
    margin: 0;
  }

  .subtable-footer {
    background-color: var(--vz-table-bg);
  }
}

// image-preview container
.image-preview-container {
  overflow: auto;
  overflow-x: hidden;
  background-color: transparent;
  border-radius: 8px;
  background-color: var(--vz-body-bg);
  padding: 16px;
}

// for divider with centered text
.centered-text-divider {
  hr {
    opacity: 0.75;
    height: 1.5px;
  }
}

// notes style
.note-container-body {
  min-height: 150px;
  height: 150px;
  overflow-y: auto;
  //
  padding: 0;

  // for individual note
  .dec-input {
    cursor: pointer;
    border: none;
    outline: none;
    resize: none;
    border-radius: 0;
  }

  .dec-input::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }

  .dec-input::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 4px;
  }

  .dec-input::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    width: 4px;
  }
}

.note-container-footer {
  padding: 0;

  .ri-delete-bin-fill {
    color: red;
  }

  div {
    display: flex;
    justify-content: end;
  }
}

.ri-delete-bin-fill {
  color: red;
}

// task in dashboard
.dashboard-tasks {
  height: calc(100vh - 257px);
  overflow: auto;
}

.notes-list {
  height: calc(100vh - 206px);
  overflow: auto;
}

// universal scroll bar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  height: 8px;
  width: 8px;
}

// custom-nav-item
.custom-nav-item {
  min-width: 150px;
  text-align: center;
  @media (max-width: 991px) {
    min-width: 130px;
  }
}

// sub-tabs-style for sub tabs
.sub-tabs-style {
  .custom-nav-item {
    height: 32px;
    min-width: 150px;
    text-align: center;

    .nav-link {
      font-size: 14px !important;
      height: 32px !important;
    }
  }
}

// table container inside card
.subcard-table-container {
  border: 1px solid var(--vz-input-border);

  .rdt_TableHeadRow {
    background-color: var(--vz-table-bg);
  }

  .rdt_Pagination {
    background-color: var(--vz-table-bg);
  }
}

// custom phone number input
.phone-number-input {
  width: 100% !important;
  font-size: 0.8125rem !important;
  line-height: 1.5 !important;
  background-color: var(--vz-input-bg) !important;
  border: 1px solid var(--vz-input-border) !important;
  border-radius: 0.25rem !important;
  height: 37.5px !important;
}

.phone-number-error {
  border: 1px solid #f06548 !important;
}

// lot add modal header css
.lot-header {
  h5 {
    height: 25px;
    width: 100%;
  }
}

// disable-action-button
.disabled-action-button {
  border: none;
  opacity: 0.4 !important;
}

// tags/status in table cell
.tag-status {
  min-width: 100px;
}

// to show minimum menu list for selection
.custom-mini-select div:last-child {
  max-height: 150px !important;
}

// import pre-contract modal
.import-precontract-modal {
  .modal-header {
    .modal-title {
      width: 100%;
    }
  }

  .modal-body {
    padding-bottom: 0px;
  }

  .modal-footer {
    padding-bottom: 4px;
  }
}

.en-field-bg {
  background-color: #f6f6f6;
}

.refernce-document-preview-section {
  .documents-container {
    font-size: 12px;
    height: 124px;
    border: 2px dashed var(--vz-input-border);
    padding: 0px 8px;
    overflow: auto;

    hr {
      margin: 0 0;
    }
  }
}

.modal-header-width-100 {
  .modal-header {
    .modal-title {
      width: 100%;
    }
  }
}

// select checkbox in data table
.table-select-checkbox {
  padding: 8px !important;
}

// table-check-box
.table-check-box {
  padding: 10px;
}

// dropzone-height-170
.dropzone-height-170 {
  min-height: 170px !important;
}

//website-management pages module
.website-pages-container {
  @media (max-width: 991px) {
    flex-direction: column;
  }

  .pages-sidebar {
    @media (max-width: 991px) {
      position: unset;
      visibility: visible;
      transform: unset;
      width: 100%;
      height: inherit;
      z-index: inherit;
    }

    .pages-menu-sidebar-scroll {
      height: calc(100vh - 250px);
      overflow: auto;
      margin-right: -16px !important;
      @media (max-width: 991px) {
        height: inherit;
      }
    }

    .header-divider {
      border: 1.5px solid black;
      background-color: black;
    }

    .pages-menu {
      max-height: calc(100vh - 296px);
      text-align: center;
      @media (max-width: 991px) {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        max-height: inherit;
        overflow-x: auto;
      }

      div {
        @media (max-width: 991px) {
          width: max-content;
        }
      }

      hr {
        margin: 8px 0px;
        @media (max-width: 991px) {
          display: none;
        }
      }

      li {
        padding: 10px 8px;

        &:hover {
          background-color: rgba(75, 56, 179, 0.18) !important;
        }

        .menu-item {
          color: var(--#{$prefix}dark);
          font-weight: $font-weight-medium;
          transition: all 0.5s ease;

          &.active,
          &:hover,
          &[aria-expanded="true"] {
            color: var(--vz-primary);
          }

          span {
            @media (max-width: 991px) {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  // home page styling
  .homepage-main-content {
    .tab-content {
      height: calc(100vh - 250px);
      overflow: auto;
      overflow-x: hidden;
      @media (max-width: 1199px) {
        height: calc(100vh - 380px);
      }
    }
  }

  // service page styling
  .services-main-content {
    .tab-content {
      height: calc(100vh - 278px);
      overflow: auto;
      overflow-x: hidden;
    }
  }

  // buying-selling page styling
  .buying-selling-main-content {
    .tab-content {
      height: calc(100vh - 278px);
      overflow: auto;
      overflow-x: hidden;
    }
  }

  // contact page styling
  .contacts-main-content {
    .tab-content {
      height: calc(100vh - 278px);
      overflow: auto;
      overflow-x: hidden;
    }
  }
}

// common error field
.input-field-error {
  border: 1px solid #f06548 !important;
}

// border box
.details-border-box {
  // .documents-container {
  font-size: 12px;
  height: 140px;
  border: 2px dashed var(--vz-input-border);
  padding: 0px 8px;
  overflow: auto;

  hr {
    margin: 0 0;
  }

  // }
}

.bids-table {
  .rdt_TableCell {
    div {
      &:first-child {
        overflow: visible !important;
      }
    }
  }
}

// for invoice line through styles.
.canceled-table-cell {
  .rdt_TableCell > div {
    text-decoration: line-through;
    color: #f1b3b3 !important;
    text-decoration-thickness: 1px !important;
  }

  .rdt_TableCell > div:has(.text-decoration-none-cell) {
    text-decoration: none;
  }
}

// for credit note fully issued for invoice
.credit-note-issued-cell {
  .rdt_TableCell:nth-child(-n+6) > div {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
  }

  .rdt_TableCell > div:has(.text-decoration-none-cell) {
    text-decoration: none;
  }
}

.multimedia-container {
  height: calc(100vh - 350px);
  overflow: auto;

  .media-container {
    .document-card {
      margin: 0.75rem 0;
      background-color: rgba(0, 0, 0, 0.68);
      border-radius: 4px;
      width: 100%;
      height: 250px;
      @media (max-width: 991px) {
        height: 150px;
      }
    }

    .image-container-card {
      cursor: pointer;
      border-radius: 4px;
      width: 100%;
      height: 250px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0.75rem 0;
      background-color: rgba(0, 0, 0, 0.68);
      @media (max-width: 991px) {
        height: 150px;
      }
    }

    .multimedia-delete-icon {
      visibility: hidden;
      transition: all 0.1s ease-in-out;
      position: absolute;
      top: 16px;
      right: 16px;
      background-color: #ffffffb5;

      &:hover {
        background-color: #ffffff !important;
      }

      &:focus,
      &:active {
        background-color: #ffffffb5 !important;
      }

      @media (max-width: 1199px) {
        visibility: visible;
      }
    }

    &:hover {
      .multimedia-delete-icon {
        @media (min-width: 1199px) {
          visibility: visible;
        }
      }
    }
  }
}

.fixed-selection-menu {
  div:last-child {
    max-height: unset;
  }
}

.edition-container {
  .card-body {
    .card-body {
      padding: 0;
    }
  }

  // edition page styling
  .edition-tab-content {
    .tab-content {
      height: calc(100vh - 306px);
      overflow: auto;
      overflow-x: hidden;
      padding-right: 8px;
    }
  }
}

.editor-block {
  .editor-toolbar {
    background-color: #f3f6f9;
    border: 1.5px solid var(--vz-input-border);
  }

  .editor-wrapper {
    border: 1.5px solid var(--vz-input-border);
    padding: 8px;
  }
}

.website-auction-info {
  .auction-text-wrapper {
    height: 300px;
  }

  .highlights-container {
    width: 100%;
    border: 1.5px solid var(--vz-input-border);
    padding: 8px;
    background-color: var(--vz-body-bg);
  }
}

.highlight-component {
  border: 1.5px solid var(--vz-input-border);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  gap: 8px;
  padding-bottom: 8px;
  background-color: white;
  width: 250px;
  position: relative;

  .lot-image {
    width: 100%;
    height: 224px;
  }

  .highlight-action-button {
    visibility: hidden;
    transition: all 0.1s ease-in-out;
    position: absolute;
    background-color: #ffffffb5;

    &:hover {
      background-color: #ffffff !important;
    }

    &:focus,
    &:active {
      background-color: #ffffffb5 !important;
    }

    @media (max-width: 1199px) {
      visibility: visible;
    }
  }

  .highlight-delete-icon {
    top: 44px;
    right: 4px;
  }

  .highlight-edit-icon {
    top: 4px;
    right: 4px;
  }

  &:hover {
    .highlight-action-button {
      visibility: visible;
    }
  }
}

// edit highlight modal
.edit-highlight-modal {
  .modal-body {
    padding-bottom: 0px;
  }

  .modal-footer {
    padding-bottom: 8px;
  }

  .highlight-media-container {
    width: 100%;
    border: 1.5px solid var(--vz-input-border);
    padding: 8px;
    border-radius: 4px;
    background-color: var(--vz-body-bg);
    display: flex;
    flex-wrap: wrap;

    .highlight-media-card {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-right: 8px;
      margin-bottom: 8px;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden;
      background-color: #ffffff;
      align-items: center;
      justify-content: center;
      padding-bottom: 8px;

      .image-container {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 80px;
        aspect-ratio: 0.85;
      }

      .highlight-action-button {
        // visibility: hidden;
        transition: all 0.1s ease-in-out;
        position: absolute;
        background-color: #ffffffb5;
        z-index: 2;
        top: 4px;
        right: 4px;
      }
    }
  }
}

// overlay styles
.overlay-container {
  position: absolute;
  background-color: blue;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

// document lines table
.document-lines-table {
  .rdt_TableCol,
  .rdt_TableCell {
    padding: 0px 8px;
  }
}

// for main customer highlight
.custom-main-select > div {
  background-color: #dcd8ee !important;
}

// react custom date picker
.custom-react-datepicker-wrapper {
  width: fit-content !important;

  .custom-react-datepicker {
  }

  .react-datepicker__month-container {
    .react-datepicker__month--selected {
      background-color: #216ba5 !important;
      color: #fff !important;
    }
  }

  .react-datepicker__calendar-icon {
    padding: 0 !important;
    top: 50%;
    left: 6%;
    transform: translate(-50%, -50%);

    path {
      fill: #808080;
    }
  }
}

.layout-main-container {
  .Invoicing-table-header {
    @media (max-width: 991px) {
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}

.auction-tab-wrapper {
  ul.nav-tabs {
    @media (max-width: 1024px) {
      display: flex;
    }

    .custom-nav-item {
      @media (max-width: 1024px) {
        min-width: inherit;
        flex: 1 1 auto;
      }
    }
  }
}

.card-section-header,
.tab-inner-section-header {
  button.btn {
    @media (max-width: 991px) {
      padding: 8px;
    }
    @media (max-width: 820px) {
      padding: 8px 3px;
      font-size: 12px;
    }
  }
}

.contract-situation-header {
  .custom-breadcrumb {
    span {
      @media (max-width: 991px) {
        font-size: 15px !important;
      }
    }
  }
}

.session-form-wrapper {
  .col-md-3,
  .col-md-2,
  .col-md-1 {
    @media (max-width: 991px) {
      padding: 0 6px;
    }

    .form-control {
      @media (max-width: 991px) {
        padding: 8px 10px;
      }
    }
  }
}

.auaction-report-count-wrap {
  .label {
    @media (max-width: 991px) {
      font-size: 14px !important;
    }
  }

  h3 {
    @media (max-width: 991px) {
      font-size: 16px !important;
    }
  }
}

input::-webkit-date-and-time-value {
  text-align: left;
}

// .card-section-header.item-header{
//   @media (max-width: 991px) {
//     flex-wrap: wrap;
//     gap: 8px
//   }
// }

.website-pages-container {
  .file-manager-content {
    @media (max-width: 991px) {
      height: calc(100vh - 350px);
    }

    .tab-content {
      @media (max-width: 991px) {
        height: calc(100vh - 445px);
        // height: calc(100vh - 475px);
        overflow: auto;
        padding-bottom: 35px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .website-pages-container {
    .file-manager-sidebar {
      height: calc(100vh - 180px);
    }

    .file-manager-content {
      height: calc(100vh - 180px);

      .tab-content {
        height: calc(100vh - 310px);
        // height: calc(100vh - 325px);
        overflow: auto;
        padding-bottom: 35px;
      }
    }
  }
}
